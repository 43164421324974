import React from 'react'
import Layout from '../../components/Layout'


export default () => (
  <Layout>
   
    <section className="section content-pages">
      <div className="container">
        <div className="content center intro">
          <h2>Professional Services</h2>
          <p>We have a team of experienced developers, designers and marketers that can work with you to get help you get started and create a beautiful well performing website for your clients</p>
        </div>

        <div className="columns content is-multiline margin-above">
          <div className="column is-6">
            <h3>CUSTOM SOFTWARE DEVELOPMENT</h3>
            <p>Our team has years of experience building powerful software solutions for the travel market. Our portfolio consists of different projects ranging from mobile application to special features that can be added to our solution.</p>
            <p>Even though our standard solution has most features built in that fits most needs we can assist with the creation of custom feature sets and changes when needed.</p>
            <p>We offer competitive prices and efficient delivery for projects of all sizes.</p>           
          </div>
          <div className="column is-6 center">

          <img src="https://images.squarespace-cdn.com/content/v1/5b9314df25bf02e0c96eca67/1536764739868-Z436KSZBKZBM7PKHIGY4/ke17ZwdGBToddI8pDm48kMRyhYDgPk9y7GMUxyVGe-RZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpzEOKU3ynhxGCOkjkiI2jkNnwo4GpKkY3nGgozguHn9FA2PHrUGCg52mo1A4uM7YTw/social.jpg?format=300w>" width="300" alt="" />
          </div>
          <div className="column is-6">
            <h3>DESIGN AND MARKETING</h3>
            <p>For most of our clients the built in templates are sufficient for their needs while others require custom designs ranging from website themes to logo design and branding. Our designers care about the user experience and understand the needs of the travel industry.</p>
            <p>Our marketing team has deep knowledge and experience in SEO, Adwords and marketing on social platforms. We share our experience with with our customers and help them make the most of their investment.</p>
          </div>
          <div className="column is-6">
            <h3>CONSULTANTS THAT ARE BOKUN EXPERTS</h3>
            <p>After years of working with the Bokun solution our team has extensive knowledge in setup and configuration of their powerful inventory and booking solution. We can help you get started and make the best of the Bokun solution.</p>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)
